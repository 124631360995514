import { Counter } from "../components/Counter";
import { Col, Container, Row } from "reactstrap";
import { useEffect, useState } from "react";
import logo from "../assets/img/instructionLogo.png";

const AdminView = (props) => {
    const [timerDays, setTimerDays] = useState();
    const [timerHours, setTimerHours] = useState();
    const [timerMinutes, setTimerMinutes] = useState();
    const [timerSeconds, setTimerSeconds] = useState();
    let interval;

    const startTimer = () => {
        const countDownDate = new Date("May 15,2025").getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();

            const distance = countDownDate - now;

            const days = Math.floor(distance / (24 * 60 * 60 * 1000));
            const hours = Math.floor(
                (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (distance % (60 * 60 * 1000)) / (1000 * 60)
            );
            const seconds = Math.floor((distance % (60 * 1000)) / 1000);

            if (distance < 0) {
                // Stop Timer

                clearInterval(interval.current);
            } else {
                // Update Timer
                setTimerDays(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds);
            }
        });
    };

    useEffect(() => {
        startTimer();
    });
    return (
        <div className="counter-page">
            <div className="p-4">
                <img src={logo} alt="" width="229px" className="bg-white p-3" />
            </div>
            <Container
                fluid
                className=" d-flex flex-column justify-content-center align-items-center pt-4 "
            >
                <h1 className="my-5 text-white">Coming Soon</h1>

                <Counter
                    timerDays={timerDays}
                    timerHours={timerHours}
                    timerMinutes={timerMinutes}
                    timerSeconds={timerSeconds}
                />
                <h1 className="my-5 text-white">15 May 2025</h1>
            </Container>
        </div>
    );
};

export default AdminView;
