import MainView from '../views/MainView';


let routes = [
	
	{
		path: '/',
		component: MainView,
		layout: 'main',
	},
];
export default routes;